import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Link from '@frontastic/catwalk/src/js/app/link'
import { generateId } from '@frontastic/common'
import Reference from '@frontastic/catwalk/src/js/component/reference'
import NodeLink from '@frontastic/catwalk/src/js/app/nodeLink'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { FormattedMessage, injectIntl } from 'react-intl'
import MediaImage from '@frontastic/catwalk/src/js/mediaImage'
import { ReactComponent as ArrowLIcon } from '../../../../../icons/sport2000-icons/arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from '../../../../../icons/sport2000-icons/arrow-right.svg'
import IconButton from '../../../atoms/button/IconButton'

const Navigation = ({
    intl,
    currentTree,
    onSelectItem,
    onBack,
    currentTopCategory,
    setCurrentActiveItem,
    setCurrentOpenedMenu,
    categoryBrand,
    saleCategory
}) => {
    const [subLevel, setSubLevel] = useState([])
    const [marketingTiles, setMarketingTiles] = useState([])

    useEffect(() => {
        setSubLevel([])
    }, [currentTopCategory])

    const handleSelectItem = (event, item) => {
        if (onSelectItem) {
            onSelectItem(event, item)
        }

        /*
            set sublevel when at brand category
        */
        if (typeof item === 'string' && categoryBrand) {
            const brands = [...categoryBrand.brandLogo?.data, ...categoryBrand.brandList?.data]
            setSubLevel(brands)
            return
        }

        setMarketingTiles(item.marketingTiles || [])
        setSubLevel([...subLevel, {
            id: item.id || item.nodeId,
            children: item.children,
            custom: item.custom,
            url: item.url,
        }])
    }

    const handleBack = () => {
        if (onBack) {
            onBack()
        }

        /*
            reset level navigation when at brand category
         */
        if (subLevel[0]?.title) {
            setSubLevel([])
            return
        }

        setSubLevel((prev) => prev.slice(0, -1))
    }

    const renderSubLevelSEO = (subLevelItem) => (
        <div className={'hidden'}>
            {subLevelItem?.children?.map((subItem) => {
                if ((!subItem.children || (subItem.children && !subItem.children.length)) && subItem.nodeId) {
                    /// @COFIXME[page-folder](FLBML-114): Replace NodeLink with PageFolderLink?
                    return (
                        <NodeLink
                            key={subItem.nodeId}
                            node={subItem}
                            title={subItem.name}
                        >
                            {subItem.name}
                        </NodeLink>
                    )
                }

                if ((!subItem.children || (subItem.children && !subItem.children.length)) && subItem.url) {
                    return (
                        <Link
                            path={subItem.url ? subItem.url : `/c/${subItem.id}/${subItem.slug}`}
                            key={subItem.id}
                        >
                            {subItem.name}
                        </Link>
                    )
                }

                return (
                    <Fragment key={subItem.id || subItem.nodeId}>
                        <a
                            href={subItem.url ? subItem.url : `/c/${subItem.id}/${subItem.slug}`}
                            title={subItem.name}
                        >
                            {subItem.name}
                        </a>
                        {renderSubLevelSEO(subItem)}
                    </Fragment>
                )
            })}
        </div>
    )

    const renderNavigation = () => {
        if ((currentTree && currentTree.children && !currentTree.children.length) && !currentTree.length) {
            return null
        }

        const nav = currentTree.children || currentTree

        return nav.map((item) => {
            if (item.name) {
                if ((!item.children || !item.children.length) && item.nodeId) {
                    /// @COFIXME[page-folder](FLBML-114): Replace NodeLink with PageFolderLink?
                    return (
                        <NodeLink
                            key={item.nodeId}
                            node={item}
                        >
                            {item.name}
                        </NodeLink>
                    )
                }

                if ((!item.children || !item.children.length) && (item.id || item.url)) {
                    const key = item.id || generateId()
                    return (
                        <Link
                            path={item.url ? item.url : `/c/${item.id}/${item.slug}`}
                            key={key}
                            onClick={() => {
                                setCurrentOpenedMenu(null)
                                setCurrentActiveItem('')
                            }}
                        >
                            {item.name}
                        </Link>
                    )
                }
                return (
                    <span
                        key={item.id || item.nodeId}
                        onClick={(e) => {
                            handleSelectItem(e, item)
                        }}
                    >
                        <a
                            href={item.url ? item.url : `/c/${item.id}/${item.slug}`}
                            onClick={(e) => {
                                e.preventDefault()
                            }}
                        >
                            {item.name}
                        </a>
                        {renderSubLevelSEO(item)}
                        {item.children && item.children.length > 0 && (
                            <span className={'is--arrow'}>
                                <ArrowRightIcon
                                    fill={'var(--color-black-100)'}
                                    width={12}
                                    height={12}
                                />
                            </span>
                        )}
                    </span>
                )
            }

            return null
        })
    }

    const renderTextLinkAllProduct = (item) => {
        const filterFieldAllText = item.custom && item.custom?.customFieldsRaw?.filter((name) => name.name === 'showAllText')

        return (
            <a
                href={item.url ? item.url : `/c/${item.id}/${item.slug}`}
                key={item.id || item.nodeId}
            >
                {(filterFieldAllText && filterFieldAllText.length > 0) ? <div>{filterFieldAllText[0]?.value}</div> : (
                    <div className={'all-text-link-mobile'}>
                        <FormattedMessage id={'header.specialCategory'} />
                    </div>
                )}
            </a>

        )
    }

    const renderSubLevel = () => {
        if (!subLevel.length) {
            return null
        }

        // render sublevel when at brand category in sepcial category aria
        if (!subLevel[0].id) {
            const renderBrandLogoWithImage = () => (
                <div className={'mx-4 my-4'}>
                    {categoryBrand && categoryBrand.brandLogo.title && (
                        <p className={'font-bold'}>{categoryBrand.brandLogo.title}</p>
                    )}
                    <div className={'brand-logo--wrapper'}>
                        {subLevel.map((brandItem) => (
                            (brandItem.media && brandItem.media.media.file) && (
                                <a
                                    href={brandItem.reference.link}
                                    key={brandItem.media?.media?.mediaId}
                                    onClick={() => {
                                        setCurrentOpenedMenu(null)
                                        setCurrentActiveItem('')
                                    }}
                                >
                                    <MediaImage media={brandItem.media} height={63} />
                                </a>
                            )
                        ))}
                    </div>
                </div>
            )

            const renderBrandLogoWithoutImage = () => (
                <>
                    {categoryBrand && categoryBrand.brandList.title && (
                        <p className={'mx-4 font-bold mb-2'}>{categoryBrand.brandList.title}</p>
                    )}
                    {subLevel.map((brandItem) => (
                        !brandItem.media && (
                            <a
                                className={'capitalize'}
                                href={brandItem.reference.link}
                                key={brandItem.media?.media?.mediaId}
                                onClick={() => {
                                    setCurrentOpenedMenu(null)
                                    setCurrentActiveItem('')
                                }}
                            >
                                {brandItem.title}
                            </a>
                        )
                    ))}
                </>
            )

            return (
                <CSSTransition
                    timeout={700}
                    classNames={'header-mobile--sub-level'}
                >
                    <div className={'header-mobile--sub-level'}>
                        {renderBrandLogoWithImage()}
                        {renderBrandLogoWithoutImage()}
                    </div>
                </CSSTransition>
            )
        }

        return (
            subLevel.map((subLevelItem, index) => (
                <CSSTransition
                    key={subLevelItem.id + index}
                    timeout={700}
                    classNames={'header-mobile--sub-level'}
                >
                    <div className={'header-mobile--sub-level'}>
                        {renderTextLinkAllProduct(subLevelItem)}
                        {subLevelItem.children.map((subItem) => {
                            if ((!subItem.children || (subItem.children && !subItem.children.length)) && subItem.nodeId) {
                                /// @COFIXME[page-folder](FLBML-114): Replace NodeLink with PageFolderLink?
                                return (
                                    <NodeLink
                                        key={subItem.nodeId}
                                        node={subItem}
                                        onClick={() => {
                                            setCurrentOpenedMenu(null)
                                            setCurrentActiveItem('')
                                        }}
                                    >
                                        {subItem.name}
                                    </NodeLink>
                                )
                            }

                            if ((!subItem.children || (subItem.children && !subItem.children.length)) && subItem.url) {
                                return (
                                    <Link
                                        path={subItem.url ? subItem.url : `/c/${subItem.id}/${subItem.slug}`}
                                        key={subItem.id}
                                        onClick={() => {
                                            setCurrentOpenedMenu(null)
                                            setCurrentActiveItem('')
                                        }}
                                    >
                                        {subItem.name}
                                    </Link>
                                )
                            }

                            return (
                                <a
                                    key={subItem.id || subItem.nodeId}
                                    onClick={(event) => {
                                        handleSelectItem(event, subItem)
                                    }}
                                >
                                    {subItem.name}
                                    <span className={'is--arrow'}>
                                        <ArrowRightIcon
                                            fill={'var(--color-black-100)'}
                                            width={12}
                                            height={12}
                                        />
                                    </span>
                                </a>
                            )
                        })}
                    </div>
                </CSSTransition>
            ))
        )
    }

    const renderBrandNavigation = () => {
        if (!categoryBrand) {
            return null
        }

        const marken = intl.formatMessage({ id: 'header.marken' })

        return (
            <a onClick={(event) => {
                handleSelectItem(event, marken)
            }}
            >
                {marken}
                <span className={'is--arrow'}>
                    <ArrowRightIcon
                        fill={'var(--color-black-100)'}
                        width={12}
                        height={12}
                    />
                </span>
            </a>
        )
    }

    const renderSaleNavigation = () => {
        if (!saleCategory) {
            return null
        }

        return (
            <a
                className={'nav-sale'}
                title={saleCategory.name}
                href={saleCategory.url}
            >
                {saleCategory.name}
            </a>
        )
    }

    return (
        <>
            <div className={classnames('header-mobile--navigation-main', {
                'is--more-sub-level': subLevel.length > 1,
            })}
            >
                <div
                    className={classnames('header-mobile--top-level', {
                        hide: subLevel.length,
                    })}
                >
                    {renderNavigation()}
                    {renderBrandNavigation()}
                    {renderSaleNavigation()}
                </div>

                <TransitionGroup>
                    {renderSubLevel()}
                </TransitionGroup>
            </div>

            {subLevel.length > 0 && (
                <IconButton
                    type={'button'}
                    ariaLabel={intl.formatMessage({ id: 'mobileNav.back' })}
                    className={'btn header-mobile--btn-back'}
                    onClick={handleBack}
                    icon={<ArrowLIcon width={16} height={16} fill={'var(--color-black-100)'} />}
                >
                    <FormattedMessage id={'mobileNav.back'} />
                </IconButton>
            )}

            {marketingTiles.length > 0 && subLevel.length > 1 && (
                <div className={'flyout-right-marketing-content'}>
                    {marketingTiles.map((marketingItem) => (
                        <div className={'flyout-marketing-item'} key={marketingItem.id}>
                            <img
                                src={marketingItem.image.url}
                                alt={marketingItem.title}
                                className={'flyout-marketing-item-img'}
                            />
                            <div className={'flyout-marketing-content'}>
                                <p className={'flyout-marketing-content-headline'}>{marketingItem.title}</p>
                                <Link
                                    path={marketingItem.url}
                                    className={'flyout-marketing-content-button'}
                                >
                                    <FormattedMessage id={'flyout.marketingBannerLink'} />
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </>
    )
}
Navigation.propTypes = {
    intl: PropTypes.object,
    currentTree: PropTypes.any,
    onSelectItem: PropTypes.func,
    onBack: PropTypes.func,
    currentTopCategory: PropTypes.number,
    setCurrentActiveItem: PropTypes.func,
    setCurrentOpenedMenu: PropTypes.func,
    categoryBrand: PropTypes.any,
    saleCategory: PropTypes.any
}

export default injectIntl(Navigation)
