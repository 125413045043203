import React from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'
import ComponentInjector from '@frontastic/catwalk/src/js/app/injector'
import getTranslation from '@frontastic/catwalk/src/js/getTranslation'
import { useRouter } from 'next/router'
import { getBaseUrl } from '@frontastic/catwalk/js/helper/getBaseUrl'

const Canonical = ({ node, context }) => {
    const router = useRouter()

    const generateCanonicalLink = () => {
        if (!node.configuration.canonical) {
            return getBaseUrl(router)
        }

        return getTranslation(
            node.configuration.canonical,
            context.locale,
            context.project.defaultLanguage
        ).text
    }

    const canonicalLink = generateCanonicalLink()

    if (!canonicalLink) {
        return null
    }

    return (
        <Head>
            <link rel="canonical" href={canonicalLink} />
        </Head>
    )
}

Canonical.propTypes = {
    node: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
}

export default ComponentInjector.return('Node.Meta.Canonical', Canonical)
