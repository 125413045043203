import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { injectIntl } from 'react-intl'
import IconLinkButton from '../../../atoms/button/IconLinkButton'
import Badge from '../../../atoms/badge'
import MiniCart from './MiniCart/index'
import { ReactComponent as CartIcon } from '../../../../../icons/sport2000-icons/cart.svg'
import { ReactComponent as UserIcon } from '../../../../../icons/sport2000-icons/user.svg'
import { ReactComponent as WishlistIcon } from '../../../../../icons/sport2000-icons/heart.svg'
import {DomElement} from '@frontastic/bridge/js/types';

const IconNavigation = ({
    intl,
    variant = '',
    cartItemsCount,
    goToCartPage,
    goToCheckoutPage,
    goToProfilePage,
    isOpenMiniCart,
    cart,
    refMiniCart,
    closeMiniCart,
    goToWishlistPage,
    wishListLineItemsCount,
}) => (
    <div className={`hidden md:flex justify-end h-full ctrl-nav ${variant}`}>
        <Badge count={wishListLineItemsCount} customCountPosition={{ top: '-4px' }}>
            <IconLinkButton
                onClick={goToWishlistPage}
                title={intl.formatMessage({ id: 'header.wishlist' })}
                className={'is--wishlist px-4 ml-1 outline-none focus:outline-none icon-header h-full -t-1'}
                icon={<WishlistIcon width={20} height={20} />}
                href={'/account/wishlists'}
            />
        </Badge>

        <IconLinkButton
            title={intl.formatMessage({ id: 'header.myAccount' })}
            className={'is--account px-4 outline-none focus:outline-none icon-header h-full'}
            icon={<UserIcon width={20} height={20} />}
            onClick={goToProfilePage}
            href={'/account'}
        />

        <Badge
            count={cartItemsCount}
            className={classnames('h-full mini-cart--wrapper', {
                active: isOpenMiniCart,
            })}
            customCountPosition={{
                top: '-4px',
                right: '-10px',
            }}
        >
            <div ref={refMiniCart}>
                <IconLinkButton
                    name={intl.formatMessage({ id: 'header.cart' })}
                    title={intl.formatMessage({ id: 'header.cart' })}
                    className={classnames('is--cart h-full pl-4 outline-none focus:outline-none icon-header', {
                        active: isOpenMiniCart,
                    })}
                    onClick={goToCartPage}
                    icon={<CartIcon width={20} height={20} />}
                    href={'/checkout/cart'}
                />
                <MiniCart
                    goToCheckoutPage={goToCheckoutPage}
                    goToCartPage={goToCartPage}
                    cart={cart}
                    closeMiniCart={closeMiniCart}
                />
            </div>
        </Badge>
    </div>
)

IconNavigation.propTypes = {
    intl: PropTypes.object.isRequired,
    variant: PropTypes.string,
    cartItemsCount: PropTypes.number,
    goToCartPage: PropTypes.func.isRequired,
    goToCheckoutPage: PropTypes.func.isRequired,
    closeMiniCart: PropTypes.func.isRequired,
    goToProfilePage: PropTypes.func.isRequired,
    cart: PropTypes.objectOf(PropTypes.any).isRequired,
    isOpenMiniCart: PropTypes.bool.isRequired,
    refMiniCart: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(DomElement) }),
    ]).isRequired,
    goToWishlistPage: PropTypes.func,
    wishListLineItemsCount: PropTypes.number,
}

IconNavigation.defaultProps = {
    cartItemsCount: 0,
}

export default injectIntl(IconNavigation)
